import React, { Fragment } from 'react';
import _size from 'lodash/size';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';

const SEPERATOR = <span className={styles.separator} />;
const RENDER = (key, value) => `${key}: ${value}`;
const GAP = '8px';

/**
 * @callback RenderCallback
 * @param {string} key
 * @param {*} value
 * @returns {React.ReactNode}
 */

/**
 * @param {Object} props
 * @param {Object} props.data
 * @param {string} [props.gap]
 * @param {string} [props.className]
 * @param {string} [props.cellClassName]
 * @param {React.ReactNode} [props.separator]
 * @param {"vertical" | "horizontal"} [props.pattern]
 * @param {RenderCallback} [props.render] render method to generate a cell for each key-value pair.
 */
export default function PaintObject({
  data = {},
  gap = GAP,
  className = '',
  cellClassName = '',
  pattern = 'vertical',
  separator = SEPERATOR,
  render = RENDER
}) {
  const size = _size(data);

  const cells = Object.entries(data).map(([key, value], index) => (
    <Fragment key={key}>
      <p className={cn(styles.cell, cellClassName)}>{render(key, value)}</p>
      <If test={index !== size - 1}>{separator}</If>
    </Fragment>
  ));

  return (
    <div
      style={{ '--gap': gap }}
      className={cn(styles.container, styles[pattern], className)}
    >
      {cells}
    </div>
  );
}

PaintObject.GAP = GAP;
PaintObject.RENDER = RENDER;
PaintObject.SEPERATOR = SEPERATOR;
