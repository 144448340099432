import STATES from './US_STATES';
import localStore from './localStore';
import { logError } from 'utils/extra';

const notifiedToSentry = [];

function sendToSentry(key) {
  if (notifiedToSentry.indexOf(key) === -1) {
    logError(`Key found in lower case - ${key}`);
    notifiedToSentry.push(key);
  }
}

const DICTIONARY = () => {
  const obj = JSON.parse(localStore.get('dictionary')) || {};
  return obj;
};

const findAllValues = key => DICTIONARY()[key];

const find = (group, key) => {
  try {
    const STORE = DICTIONARY()[group];
    if (STORE[key]) {
      return STORE[key];
    } else if (key && STORE[key.toUpperCase()]) {
      // This is a lack of consistency in the backend. Even though we don't want the user to suffer
      // We want to report such errors (Sarthak)
      sendToSentry(key);
      return STORE[key.toUpperCase()];
    } else {
      return key;
    }
  } catch (e) {
    logError('Error in dictionary: ' + e);
    return key;
  }
};
const Shipping_Method = key => {
  return find('retailerShippingMethodChoices', key);
};
const Refund_Method = key => {
  return find('retailerPaymentMethodChoices', key);
};
const findCurrency = key => {
  if (!key) {
    return null;
  }
  return find('currencySymbolChoices', key);
};

// Alias to a more general name
const paymentMethod = Refund_Method;

const findEcommPlatform = key => find('ecommerce_platforms', key);

const Ret_Exc_Status = key => find('retexStatusChoices', key);

const deliveryStatusChoices = key => find('deliveryStatusChoices', key);

const entityTypeChoices = key => find('entityTypeChoices', key);

const financialStatus = key => find('order_financial_statuses', key);

const paymentWaiveOffHelpText = () => find('paymentWaiveOff', 'infoText');

export const activeServiceRequestChoices = () => {
  return DICTIONARY()['inprogress_request_status'] || [];
};

export const rejectedServiceRequestChoices = () => {
  return DICTIONARY()['rejected_service_request_status'] || [];
};

export const approvedServiceRequestChoices = () => {
  return DICTIONARY()['successful_request_status'] || [];
};

export const pickupSlotChoices = () => {
  return DICTIONARY()['pickup_slot_choices'] || {};
};

export {
  findAllValues,
  Shipping_Method,
  Refund_Method,
  Ret_Exc_Status,
  STATES,
  paymentMethod,
  findEcommPlatform,
  deliveryStatusChoices,
  entityTypeChoices,
  financialStatus,
  findCurrency,
  paymentWaiveOffHelpText
};
