import _empty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

import { post, logError } from 'utils';

export default function getOvms(
  { ids = [], orderId = '' } = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const hasNoIds = _empty(ids);
  if (hasNoIds) return onSuccess([]);

  post('/retailer/order-variant-maps/', { params: { ids, order_id: orderId } })
    .then(({ data }) => {
      const ovms = _isArray(data) ? data : [];
      onSuccess(ovms);
    })
    .catch(error => {
      const errStr = JSON.stringify(error);
      logError(
        new Error({
          errStr,
          meta: { ids, orderId, errorMsg: 'fetching order variant maps failed' }
        })
      );
      onError(error);
    })
    .finally(onFinally);
}
