import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import _get from 'lodash/get';
import qs from 'qs';

import { If } from 'utils';
import { ThemeBlock, PoweredByBar } from 'components/Common';
import { LogoBanner, Header, QrLabelLocations } from './components';
import showPoweredByAspen from 'utils/methods/showPoweredByAspen';
import getQrLabelSupportedLocations from './services/getQrLabelSupportedLocations';

export default function QrShippingLabel({ storeName }) {
  const location = useLocation();
  const [zipCode, setZipCode] = useState('');
  const [locations, setLocations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState(null);
  const hasAspenBranding = showPoweredByAspen();

  useEffect(() => {
    window.scrollTo(-100, 0);
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const defaultZip = _get(queryParams, 'zipcode', '');
    if (defaultZip) {
      setZipCode(defaultZip);
      fetchLocations(defaultZip);
    }
  }, []);

  const fetchLocations = zip => {
    const postalCode = zip || zipCode;
    setIsLoading(true);
    const queryParams = { postal_code: postalCode };
    getQrLabelSupportedLocations(
      { params: queryParams },
      data => {
        setLocations(data);
        setFetchingError(null);
      },
      err => {
        setLocations(null);
        setFetchingError(err);
      },
      () => setIsLoading(false)
    );
  };

  return (
    <>
      <If test={storeName}>
        <Helmet>
          <title>{storeName} Support | QR Supported Locations</title>
        </Helmet>
      </If>
      <div className="qrShippingLabel">
        <LogoBanner />
        <div className="qrShippingLabel__container">
          <Header
            zipCode={zipCode}
            isLoading={isLoading}
            onChangeZipCode={setZipCode}
            onSearchLocations={fetchLocations}
          />
          <QrLabelLocations
            zipCode={zipCode}
            locations={locations}
            isLoading={isLoading}
            isError={!!fetchingError}
          />
        </div>
        <If test={hasAspenBranding}>
          <ThemeBlock
            opacity={0.25}
            containerClassname="powered-by-footer-container"
            content={<PoweredByBar className="ml-0 mr-0" />}
          />
        </If>
      </div>
    </>
  );
}
