import React, { useContext, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import { useEcomPlatform } from 'utils/hooks';
import { getGiftCardCopy } from 'utils/methods';
import { AuthContext } from 'context/AuthContext';
import { ReactComponent as LogoutIcon } from 'images/icons/logout.svg';

export default function Header({ data }) {
  const { push } = useHistory();
  const { logout } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { checkIfPlatformIs } = useEcomPlatform();

  const isBigCommerce = checkIfPlatformIs('BIGCOMMERCE');
  const { giftCardText } = getGiftCardCopy();

  const avatar = useMemo(() => {
    const { avatarPicUrl = '', firstName = '', lastName = '' } = data;
    return (
      avatarPicUrl ||
      `https://ui-avatars.com/api/?name=${firstName}+${lastName}`
    );
  }, [data]);

  const onToggle = () => setDropdownOpen(isOpen => !isOpen);

  const onLogout = () => logout(() => push('/login'));

  return (
    <ul className="nav navbar-nav ml-auto ">
      <li className="nav-item mobile-link">
        <div
          className="nav-link"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="text-right">{data.firstName}</div>
          <div className="text-right email">({data.email})</div>
        </div>
      </li>
      <Dropdown className="nav-item" isOpen={dropdownOpen} toggle={onToggle}>
        <DropdownToggle className="nav-link mr-0 border-0">
          <img className="img-avatar" src={avatar} alt="" />
        </DropdownToggle>
        <DropdownMenu
          onClick={onToggle}
          right
          style={{ right: 'auto', height: 'auto' }}
        >
          <Link className="dropdown-item" to="/account/orders">
            My Orders
          </Link>
          {!isBigCommerce && (
            <Link className="dropdown-item" to="/account/gift-cards">
              {giftCardText}
            </Link>
          )}
          <Link to="#" className="dropdown-item logout" onClick={onLogout}>
            Logout
            <LogoutIcon />
          </Link>
        </DropdownMenu>
      </Dropdown>
    </ul>
  );
}
