import { useMemo } from 'react';

import Color from 'color';

const DARK_COLOR = [12, 19, 26];
const WHITE_COLOR = [255, 255, 255];
const RR_DEFAULT_THEME_COLOR = '#e94636';
const THEME = {
  primary_color: '--theme__primary--color',
  primary: '--theme__primary-btn--background-color',
  secondary: '--theme__secondary-btn--background-color'
};

/**
 * @param {Object} [options={}] - Options
 * @param {keyof typeof THEME} [options.theme='primary_color'] - Theme key
 * @param {Number} [options.opacity=1] - 0 to 1
 * @returns {Record<"textColor" | "backgroundColor", string>} Object with textColor and backgroundColor
 */
export default function useDynamicText({
  theme = 'primary_color',
  opacity = 1
} = {}) {
  return useMemo(() => {
    const computedStyles = getComputedStyle(document.body);
    const color = computedStyles.getPropertyValue(THEME[theme]);
    const themeColor = color ? color : RR_DEFAULT_THEME_COLOR;
    const bgColor = Color(themeColor).alpha(opacity);
    const textColor = Color(opacity < 0.6 ? DARK_COLOR : WHITE_COLOR);

    return {
      textColor: textColor.string(),
      backgroundColor: bgColor.string()
    };
  }, [theme, opacity]);
}
