import React from 'react';

import { useThemeColor } from 'utils/hooks';

export default function ThemeBlock({
  opacity = 1,
  content = null,
  fontSize = '14px',
  containerClassname = ''
}) {
  const { textColor, backgroundColor } = useThemeColor({ opacity });

  return (
    <div
      className={containerClassname}
      style={{
        color: textColor,
        backgroundColor: backgroundColor,
        fontSize: fontSize,
        fill: textColor
      }}
    >
      {content}
    </div>
  );
}
