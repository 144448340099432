import React, { useMemo, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import _size from 'lodash/size';
import styles from './index.module.scss';

import { If } from 'utils';
import { PaintObject } from 'components';

export default function ProductVariantAttributes({
  optionValues = {},
  showCount = 2,
  className = '',
  textClassName = '',
  tooltipClassName = '',
  tooltipTextClassName = '',
  gap = PaintObject.GAP,
  render = PaintObject.RENDER,
  separator = PaintObject.SEPERATOR
}) {
  const id = useRef(`attributes-${uuidv4()}`).current;

  const renderAttributes = useMemo(() => {
    return Object.entries(optionValues)
      .slice(0, showCount)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }, [optionValues, showCount]);

  const additionalAttributesCount = _size(optionValues) - showCount;
  const hasAdditionalAttributes = additionalAttributesCount > 0;

  return (
    <div className={cn(styles.container, className)} style={{ '--gap': gap }}>
      <div id={id} className={styles.wrapper}>
        <div className={styles.content}>
          <PaintObject
            gap={gap}
            pattern="horizontal"
            data={renderAttributes}
            separator={separator}
            cellClassName={textClassName}
            render={render}
          />
          <If test={hasAdditionalAttributes}>
            {separator}
            <span className={cn(styles.extraInfo, textClassName)}>
              +{additionalAttributesCount} more...
            </span>
          </If>
        </div>
      </div>
      <UncontrolledTooltip target={id}>
        <PaintObject
          separator={null}
          pattern="vertical"
          data={optionValues}
          className={cn(styles.tooltip, tooltipClassName)}
          cellClassName={cn(styles.tooltipText, tooltipTextClassName)}
        />
      </UncontrolledTooltip>
    </div>
  );
}
